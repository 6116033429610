import React, {PureComponent} from 'react';
import classNames from 'classnames';
import brickWrapper from 'businessLogic/core/bricks/brickWrapper';
import Container from 'businessLogic/core/shared/Container/Container';
import brickImage from './img/brickPreview.jpg';
import CallActionTestBrickWithBoxes from 'businessLogic/core/shared/Test/CallActionTestBrickWithBoxes';
import ModalForTestBricks from 'businessLogic/core/shared/Test/ModalForTestBricks';
import InstructionTestBrick from 'businessLogic/core/shared/Test/InstructionTestBrick';
import FinalFeedback from './components/FinalFeedback';
import {CSSTransition, TransitionGroup} from 'react-transition-group';
import Test from './components/Test';

import get from 'lodash/get';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';
import {gettext as _} from 'businessLogic/scope/user/helper/userTtag';
import editionFields from './edit.js';
import FrontEditableText from 'businessLogic/shared/Text/FrontEditableText';
import './styles.scss';

class TestExam extends PureComponent {
	static editionFields = editionFields;
	static brickName = () => t`Test de examen`;
	static brickIcon = 'test';
	static brickImage = brickImage;
	static brickHelpText = () =>
		t`Permite al administrador evaluar los conocimientos del usuario sobre el contenido aprendido. Puede incluir tiempo de duración del examen y nota de corte.`;
	static canReload = true;
	static brickDefaultConfig = () => ({
		brickName: t`Test de examen`,
		pointSuccess: 10,
		markCutOff: 50,
		maxAttempts: 1,
		duration: 60,
		brickDescription: t`Este test de examen nos permitirá evaluar los conocimiento adquiridos por el alumno. Las respuestas falladas restan puntuación, las no contestada, no.`,
		requiredActions: true,
		wrongAnswers: false,
	});

	static brickDefaultData = () => ({
		/*Datos comunes a todos los elementos del repeater */
		image: {
			imageSrc: '/uploads/default-images/ilustration_test.svg',
			credit: '',
		},
		beforeHeading: _('Test de examen'),
		title: _('Test final con varias preguntas'),
		textButtonStart: _('EMPEZAR'),
		textLinkInstructions: '',
		instructions: '',
		textButtonAgain: _('REPETIR'),
		textButtonShowsolutions: _('VER RESPUESTAS'),
		questions: [
			{
				_id: 1,
				statement: '¿Lorem ipsum dolor sit amet consectetur adipiscing elit?',
				imgStatement: {
					imageSrc: '/uploads/default-images/csDefault1Opt.svg',
					footer: '',
					credit: '',
				},
				answers: [
					{
						_id: 1,
						answer: 'Sociis feugiat nulla venenatis aenean, netus suspendisse.',
						valueSolution: true,
					},
					{
						_id: 2,
						answer: 'Lorem ipsum dolor sit amet consectetur, adipiscing elit augue.',
						valueSolution: false,
					},
					{
						_id: 3,
						answer: 'Lorem ipsum dolor sit amet consectetur, adipiscing elit augue.',
						valueSolution: false,
					},
					{
						_id: 4,
						answer: 'Lorem ipsum dolor sit amet consectetur, adipiscing elit augue.',
						valueSolution: false,
					},
				],
			},
		],
	});

	constructor(props) {
		super(props);
		this.state = {
			type: 'TestExam',
			stateMachine: 'intro',
			completed: false,
			success: false,
			responses: {},
			scores: {},
			attempts: 0,
			score: {
				min: 0,
				max: 0,
				raw: 0,
				scaled: 0,
			},
		};

		this.responses = {};
		this.solutions = {};
		this.successes = 0;
		this.failures = 0;
		this.coefSuccess = 0;
		this.testExam = React.createRef();

		this.props.getBrickStateData('').then((brickStateData) => {
			if (brickStateData && brickStateData.state) {
				this.status = brickStateData.status;

				// Nos aseguramos de que el status prevalece sobre el state
				this.updateStateFromStatus(this.status, brickStateData.state);

				this.setState(brickStateData.state);

				this.responses = get(
					brickStateData,
					['state', 'responses', get(brickStateData, 'state.attempts', 0)],
					{},
				);

				//En caso de que se inicie en test porque se recargo el navegador
				if (brickStateData.state.stateMachine === 'test') {
					this.initStateTest();
				}

				//Parche para solventar los casos donde no se ha guardado el status del ladrillo
				if (
					brickStateData.state.stateMachine === 'result' &&
					(!brickStateData.status ||
						get(brickStateData, 'status.success', false) !==
							get(brickStateData, 'state.success', false))
				) {
					this.initStateTestResult();
				}
			}
		});
	}

	// El status siempre debe prevalecer frente a los datos del estado en caso de ser positivos
	updateStateFromStatus(status, state) {
		let fixed = false;
		const prevState = {...state};
		if (status?.success && !state.success) {
			state.success = true;
			fixed = true;
		}

		if (status?.completed && !state.completed) {
			state.completed = true;
			fixed = true;
		}

		if (status?.score?.raw > state.score.raw) {
			state.score = status.score;
			fixed = true;
		}

		if (fixed) {
			prevState.dateFixed = new Date();
			state.prevState = prevState;
			state.stateMachine = 'result';
		}
	}

	saveBrickState = () => {
		this.props.setBrickStateData('state', this.state);
	};

	parseDataTypeNumber = (nameConfig, getDefaultValue) => {
		const data = get(this.props.brick.config, nameConfig, 0);
		const convertData = parseFloat(data);
		if (isNaN(convertData)) return getDefaultValue;
		return convertData;
	};

	//Crear un listado de soluciones para compararlo con las respuestas y corregir el examen
	getSolutions = () => {
		//Crear un copia de los datos originales de questions
		const dataQuestions = get(this.props.brick.data, ['questions'], []).slice();
		let correctAnswers;
		let valueSolution = [];
		let itemSolution;

		for (let question of dataQuestions) {
			//Respuesta correctas
			correctAnswers = question.answers.filter((answer) => answer.valueSolution === true);
			valueSolution = correctAnswers.map((answer) => answer._id);
			itemSolution = {
				[question._id]: valueSolution,
			};

			Object.assign(this.solutions, itemSolution);
		}
		return this.solutions;
	};

	toCorrectQuestion = (response, solution) => {
		//Caso I existe la clave porque el usuario seleccionó la pregunta pero luego la deseleccionó y se quedo sin contenstar
		if (response === '') return undefined;

		const resultResponse = solution.findIndex((optionSolution) => optionSolution === response);

		//Caso II las respuesta del usuario no se encuentra entre las posibles verdaderas
		if (resultResponse < 0) return false;

		///Caso III acierto
		return true;
	};

	getNumberOptionAnswer = (idQuestion) => {
		const dataQuestions = get(this.props.brick.data, ['questions'], []).slice();
		const indexQuestion = dataQuestions.findIndex(
			(question) => question._id.toString() === idQuestion,
		);
		const answers = get(dataQuestions[indexQuestion], 'answers', []);
		return answers.length;
	};

	getScore = () => {
		const totalQuestions = Object.keys(this.solutions).length;
		const valueSuccess = this.parseDataTypeNumber('pointSuccess', 1);
		const maxScore = totalQuestions * valueSuccess;
		const userScore = this.coefSuccess < 0 ? 0 : parseFloat(this.coefSuccess) * valueSuccess;
		return {
			min: 0,
			max: maxScore,
			raw: Math.round(userScore),
			scaled: userScore / maxScore,
		};
	};
	getResposes = () => this.responses;

	toCorrectExam = () => {
		// Antes de corregir, establecemos los contadores a 0
		this.successes = 0;
		this.coefSuccess = 0;
		this.failures = 0;

		//Acualizar this.solutions con las soluciones guardadas en el this.props.data
		this.getSolutions();
		const wrongAnswers = get(this.props.brick.config, 'wrongAnswers', true);

		//Contabilizar total de fallos y aciertos. Las preguntas en blanco no computan
		for (let response in this.responses) {
			const result = this.toCorrectQuestion(this.responses[response], this.solutions[response]);

			if (result !== undefined) {
				if (result) {
					this.successes++;
					this.coefSuccess++;
				} else {
					this.failures++;

					// la penalización por pregunta fallada debe tener alguna relación con el número de opciones de respuesta que tenemos en cada pregunta.
					// Nota total = Aciertos - (Errores / k-1). Siendo k el numero de posibles respuesta por pregunta
					//http://calculnote.atspace.eu/ Ejemplo

					// -- la penalización queda a elección del administrador --
					// -- en configuración del test si está activo el switch ¿las respuestas incorrectas restan puntos? --
					if (wrongAnswers) {
						const k = this.getNumberOptionAnswer(response);
						const failurePerQuestion = 1 / (k - 1);
						this.coefSuccess = this.coefSuccess - failurePerQuestion;
					}
				}
			}
		}
	};

	isPassedExam = (score) => {
		//Nota de corte
		const markCutOff = this.parseDataTypeNumber('markCutOff', 0);
		if (
			this.coefSuccess <= 0 ||
			(markCutOff && markCutOff > 0 && score.scaled * 100 < markCutOff)
		) {
			return false;
		}
		return true;
	};

	isClosedExam = () => {
		const maxAttempts = this.parseDataTypeNumber('maxAttempts', 0);
		if (maxAttempts > this.state.attempts) {
			return false;
		}
		return true;
	};

	/**
	 * FUNCIONES ENCARGADAS DE INICIAR LAS MÁQUINAS DE ESTADO
	 * ***/

	initStateTest = () => {
		this.setState((state) => {
			let newAttempts = state.attempts;
			if (!this.state.success && !this.isClosedExam()) {
				newAttempts++;
				this.successes = 0;
				this.coefSuccess = 0;
				this.failures = 0;
				this.responses = {};
			} else {
				this.showSolutions = true;
			}
			return {
				stateMachine: 'test',
				attempts: newAttempts,
			};
		}, this.saveBrickState);

		//Lanzamos el evento de inicio de test
		this.props.brickEvent('start');
	};

	initStateTestResult = async () => {
		if (!this.state.success) {
			this.toCorrectExam();
			const score = this.getScore();
			const success = this.isPassedExam(score);
			const newResponses = Object.assign({}, this.state.responses);
			newResponses[this.state.attempts] = this.responses;
			const newScores = Object.assign({}, this.state.scores);
			newScores[this.state.attempts] = score;

			const prevStatus = await this.props.getBrickStateData('status');
			const successStatus = get(prevStatus, 'success', false);
			const prevScore = get(prevStatus, 'score', {});
			const prevScoreRaw = get(prevScore, 'raw', 0);

			//Marcamos el test como completado
			const status = {
				progress: 100,
				completed: true,
				success: success || successStatus,
			};

			// Solo actualizamos el score si es mayor que el anterior y ha superado el examen
			// if (score.raw > prevScoreRaw || status.success) {
			// Mantenemos la comprobación anterior por compatibilidad con el reto mpa
			// TODO: Incluir la comprobación de success una vez acabado el reto mpa
			if (score.raw > prevScoreRaw) {
				status.score = score;
			}

			this.props.setBrickStateData('status', status).then(() => {
				this.setState(
					{
						stateMachine: 'result',
						completed: true,
						score,
						success,
						responses: newResponses,
						scores: newScores,
					},
					this.saveBrickState,
				);

				//Lanzamos el evento de fin de test
				this.props.brickEvent('end');

				this.props.configObject.callEventListeners('brickStatusChanged', {
					brick: 'TestExam',
					type: 'evaluate',
					status,
				});
			});
		} else {
			// Si status.success no es true, enviamos de nuevo el status y notificamos para que se actualice el curso
			// Esto indica que se actualizó el state pero no el status
			if (!get(this.status, 'success', false)) {
				const status = {
					progress: 100,
					completed: this.state.completed,
					success: this.state.success,
					score: this.state.score,
				};
				this.props.setBrickStateData('status', status).then(() => {
					this.props.configObject.callEventListeners('brickStatusChanged', {
						brick: 'TestExam',
						type: 'evaluate',
						status,
					});
				});
			}

			this.setState({stateMachine: 'result'}, this.saveBrickState);
		}
	};

	render() {
		const data = this.props.brick.data;
		const config = this.props.brick.config;
		const showSolutionsOption = get(config, 'showSolutions', false);
		const content = data.questions || [];
		const markCutOff = this.parseDataTypeNumber('markCutOff', 0);
		const maxDuration = this.parseDataTypeNumber('duration', 0);
		const mainStyleClass = 'test-exam-brick';
		const classes = classNames({
			brick: true,
			[`${mainStyleClass}`]: true,
		});

		const showSolutions = this.state.success || (this.isClosedExam() && showSolutionsOption);
		const customizeResultTexts = get(config, 'customizeResultTexts.switchValue', false);
		const successText = get(config, 'customizeResultTexts.successText', '');
		const failText = get(config, 'customizeResultTexts.failText', '');

		const resultText = this.state.success ? successText : failText;
		const initialImage = data.image;
		const imageSuccess = get(config, 'customizeResultTexts.imageSuccess', initialImage);
		const imageFail = get(config, 'customizeResultTexts.imageFail', initialImage);

		const resultImage = this.state.success ? imageSuccess : imageFail;

		let intro = (
			<CSSTransition key="intro" timeout={500} classNames="show">
				<div className="step stept--1">
					<CallActionTestBrickWithBoxes
						type="toStart"
						textButton={data.textButtonStart}
						beforeHeading={data.beforeHeading}
						image={data.image}
						onClick={this.initStateTest}
						set={this.props.set}
						className={classes}
					>
						<div className={mainStyleClass + '__title'}>
							{/* <p dangerouslySetInnerHTML={{__html: setEllipsisText(data.title, 40)}} /> */}
							<FrontEditableText fieldName="title" text={data.title} set={this.props.set} />
						</div>
						<InstructionTestBrick
							textLabel={data.textLinkInstructions}
							textHelp={data.instructions}
							theme="dark"
						/>
					</CallActionTestBrickWithBoxes>
				</div>
			</CSSTransition>
		);
		let exam = (
			<CSSTransition key="test" timeout={500} classNames="show">
				<div className="step stept--2">
					<ModalForTestBricks
						onClose={this.initStateTestResult}
						isEditMode={this.props.editMode}
						exitMessage={
							this.showSolutions
								? undefined
								: _('¿Quieres abanadonar el test? si lo haces, te contará como un intento')
						}
					>
						<Test
							beforeHeading={data.beforeHeading}
							collectionContent={content}
							onFinishTest={(data) => {
								this.responses = Object.assign(this.responses, data);
								this.initStateTestResult();
							}}
							maxDuration={maxDuration}
							disable={this.showSolutions ? true : false}
							submissions={
								this.state.completed ? this.state.responses[this.state.attempts] : undefined
							}
							set={this.props.set}
						/>
					</ModalForTestBricks>
				</div>
			</CSSTransition>
		);
		let result = (
			<CSSTransition key="result" timeout={500} classNames="show">
				<FinalFeedback
					isSuccess={this.state.success}
					set={this.props.set}
					image={customizeResultTexts ? resultImage : data.image}
					dataText={{
						beforeHeading: customizeResultTexts ? resultText : data.beforeHeading,
						textButton: showSolutions ? data.textButtonShowsolutions : data.textButtonAgain,
						markCutOff: markCutOff,
					}}
					onClick={this.initStateTest}
					isClosedTest={this.isClosedExam() && !showSolutions}
					score={this.state.success ? this.state.score : undefined}
				/>
			</CSSTransition>
		);

		let step;
		switch (this.state.stateMachine) {
			case 'intro':
				step = intro;
				break;
			case 'test':
				step = exam;
				break;
			case 'result':
				step = result;
				break;
			default:
				step = intro;
		}

		return (
			<Container size="full-width">
				<div className={classes}>
					<TransitionGroup className="todo-list">
						{this.state.dataSent ? result : step}
					</TransitionGroup>
				</div>
			</Container>
		);
	}
}

export default brickWrapper(TestExam);
