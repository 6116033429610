/* eslint-disable no-console */
import React, {Component} from 'react';
import store, {client} from 'businessLogic/store/store';
import {push} from 'businessLogic/store/data/router';
import {Provider} from 'react-redux';
import Router from 'businessLogic/routes/Router';
import {ApolloProvider} from 'react-apollo';
import WindowSizeContext from 'businessLogic/contexts/WindowSizeContext';
import debounce from 'lodash/debounce';
import {ENTITY_QUERY} from 'businessLogic/store/data/entity/queries';
import {PILL_QUERY, CHECK_JOIN_QUERY} from 'businessLogic/store/data/pills/graphql/query';
import {GET_USER_DATA} from 'businessLogic/store/data/session/graphql/query';
import {getParamsURL} from 'businessLogic/helpers/functions';
import {checkWindowWidth} from 'businessLogic/helpers/functions';

class App extends Component {
	constructor(props) {
		super(props);
		this.preview = getParamsURL(window.location.href, 'preview') === 'true' ? true : false;
		this.state = {
			loading: process.env.REACT_APP_EVENTS_API === 'scorm',
			size: {
				width: window.innerWidth,
				height: window.innerWidth,
				device: checkWindowWidth(),
				preview: this.preview,
			},
		};
		if (this.state.loading) {
			this.loadScormData();
		}
	}

	debouncedHandleWindowResize = debounce(() => {
		this.setState({
			size: {
				width: window.innerWidth,
				height: window.innerWidth,
				device: checkWindowWidth(),
				preview: this.preview,
			},
		});
	}, 200);

	nativeAppPushUrl = (event) => {
		const url = event.detail;
		push(url);
	};

	nativeAppDeviceInfo = (event) => {
		if (localStorage) {
			localStorage.setItem('nativeAppDeviceInfo', JSON.stringify(event.detail));
		}
	};

	nativeAppHeartbeat = () => {
		if (window.ReactNativeWebView) {
			window.ReactNativeWebView.postMessage(
				JSON.stringify({action: 'heartbeat', timestamp: Date.now()}),
			);
		}
	};

	componentDidMount() {
		window.addEventListener('resize', this.debouncedHandleWindowResize);

		// Evento para recibir cambios de página desde NativeApp
		window.addEventListener('nativeApp.url.push', this.nativeAppPushUrl);

		// Evento para recibir heartbeat desde NativeApp
		window.addEventListener('nativeApp.heartbeat', this.nativeAppHeartbeat);

		// Evento para recibir información del dispositivo
		window.addEventListener('nativeApp.deviceInfo', this.nativeAppDeviceInfo);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.debouncedHandleWindowResize);

		// NativeApp events
		window.removeEventListener('nativeApp.url.push', this.nativeAppPushUrl);
		window.removeEventListener('nativeApp.heartbeat', this.nativeAppHeartbeat);
		window.removeEventListener('nativeApp.deviceInfo', this.nativeAppDeviceInfo);
	}

	//Introduce los datos necesarios para cargar el curso en scorm en la caché de Apollo
	loadScormData = async () => {
		// Obtenemos en primer lugar los datos que tenemos que precargar en caché
		const response = await fetch('scormCourse.json', {
			method: 'get',
		});

		const responseJson = await response.json();

		// Cambiamos el id del curso por uno por defecto para scorm
		// e incluimos la versión en el slug del curso, ya que para scorm no va a hacer falta
		responseJson.course.id = 'scormCourseId';
		responseJson.course.slug = responseJson.course.scormVersion;
		client.writeQuery({
			query: ENTITY_QUERY,
			data: {currentEntity: responseJson.entity},
		});
		console.log('writeQuery ENTITY_QUERY');

		client.writeQuery({
			query: ENTITY_QUERY,
			variables: {
				preview: false,
			},
			data: {currentEntity: responseJson.entity},
		});
		console.log('writeQuery ENTITY_QUERY');

		client.writeQuery({
			query: PILL_QUERY,
			variables: {
				id: responseJson.course.id,
			},
			data: {pill: responseJson.course},
		});
		console.log('writeQuery PILL_QUERY');

		const dummyUser = {
			id: 'scormUser',
			name: 'Scorm User',
			email: 'scormuser@scormuser.com',
			admin: false,
			superAdmin: false,
			data: null,
			adminAsUser: null,
			adminAsGroup: null,
			__typename: 'LoggedUser',
			config: null,
			lang: null,
			imageObj: null,
		};

		client.writeQuery({
			query: GET_USER_DATA,
			data: {dataUser: dummyUser},
		});
		console.log('writeQuery GET_USER_DATA');

		client.writeQuery({
			query: CHECK_JOIN_QUERY,
			variables: {
				id: 'scormCourseId',
			},
			data: {checkJoin: true},
		});
		console.log('writeQuery CHECK_JOIN_QUERY');

		this.setState({loading: false});
	};

	render() {
		if (this.state.loading) {
			return null;
		}
		return (
			<ApolloProvider client={client}>
				<Provider store={store}>
					<WindowSizeContext.Provider value={this.state.size}>
						<Router />
					</WindowSizeContext.Provider>
				</Provider>
			</ApolloProvider>
		);
	}
}

export default App;
